<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">当前位置：<router-link to="/">首页</router-link>><router-link to="/info">个人中心</router-link>><a
            href="javascript:;">发票管理</a></div>
      </div>
      <div class="personal per_invoice pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info">基本信息</router-link>
          <router-link to="/certificate">证书抬头</router-link>
          <router-link to="/maintain_list">开票信息</router-link>
          <router-link to="/address_list">收件地址</router-link>
          <router-link to="/invoices_status" class="active">发票管理</router-link>
          <router-link to="/contract_management">合同管理</router-link>
          <router-link to="/change_password">修改密码</router-link>
          <router-link to="/change_phone">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
        </div>
        <div class="right_box flex-grow-1">
          <ul class="order_list">
            <li class="mb30">
              <div class="flex-box num_date">
                <div class="col9">订单号：</div>
                <div>{{detail.tradeNo}}</div>
                <div class="pl60 col9">{{detail.createTime}}</div>
              </div>
              <div class="goods_info flex-box">
                <img :src="oss + product.thumbnail" class="img_about mr20" alt="" />
                <div class="title flex-grow-1 flex-box flex-col flex-between flex-col-start">
                  <div class="fs20 fwb">{{product.productName}}</div>
                  <div class="fs28 red fwb"><span class="fs16">¥</span>{{detail.totalAmount}}</div>
                </div>
                <div class="title fifteen flex-box flex-col flex-between flex-col-start">
                  <div class="fs20 hundred">数量</div>
                  <div class="fs20 hundred">x{{detail.totalCount}}</div>
                </div>
                <div class="title ten flex-box flex-col flex-between flex-col-start">
                  <div class="fs20 hundred">状态</div>
                  <div class="fs20 hundred">已完成</div>
                </div>
              </div>
            </li>
          </ul>
          <div class="invoice_info">
            <div class="title fs18 fwb">发票信息</div>
            <div class="flex-box fs16 deilvery">
              <div>发票状态</div>
              <div class="normal">已开票</div>
            </div>
            <div class="flex-box fs16 deilvery">
              <div>发票类型</div>
              <div>纸质{{tradeInvoice.invoiceType=='COMMON'?'普通':'专用'}}发票</div>
            </div>
            <div class="flex-box fs16 deilvery">
              <div>快递公司</div>
              <div>{{deliveryCom}}</div>
            </div>
            <div class="flex-box fs16 deilvery">
              <div>快递单号</div>
              <div>{{deliveryInfo.deliveryNo}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import cookie from "vue-cookies";
export default {
  data() {
    return {
      oss: this.global.uploadOss,
      detail: {},
      product:{},
      tradeInvoice:{},
      deliveryInfo:{},
      deliveryCom:'', //快递公司
    }
  },
    // 页面渲染完执行
    created() {
    let that = this;
    var id = unescape(that.$route.query.code)
    that.id = id.replace('环保桥', '')
    that.order_detail(that.id);
  },
  methods: {
       //订单详情
       order_detail(id) {
      let that = this;
      let data = {
        id: id,
        deviceType: "1",
        pkg_product_father: 'com.dazz.business.model.product.Product',
        pkg_tradeInvoice: 'com.dazz.business.model.tradeInvoice.TradeInvoice',
        pkg_invoiceDeliveryInfo:'com.dazz.business.model.invoiceDeliveryInfo.InvoiceDeliveryInfo'
      };
      that.$api.apiOrderDetail(data).then((res) => {
        if (res.resCode == 0) {
          let data = res.root[0]
          if(data.product){
            that.product = data.product[0]
          }
          if(data.tradeInvoice){
            that.tradeInvoice = data.tradeInvoice[0]
          }
          if(data.invoiceDeliveryInfo){
            if(data.invoiceDeliveryInfo[0].deliveryCom){
               that.get_express(data.invoiceDeliveryInfo[0].deliveryCom)
            }
            that.deliveryInfo = data.invoiceDeliveryInfo[0]
          }
          that.detail = data
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

     //数据字典查询快递公司
        get_express(name){
				  let that = this
          let data = {
            tag: 'UML3GSQKKUII',
            pkg_children: 'com.dazz.business.model.setting.CommonSetting'
          }
          that.$api.apiPostSetting(data).then((res) => {
            if (res.resCode == 0) {
              if (res.root.length > 0) {
                let root = res.root[0].children
                if(root.length >0)
                 root.forEach(function (item) {
                    if(item.value == name){
                       that.deliveryCom = item.label
                       return;
                    }
                 })
              }
            } else {
              that.$message.error(res.resMsg);
            }
          }).catch((err) => {
            console.log(err);
          });
        },

 //删除
 cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id}).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },

  }
}
</script>


